import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedItem: [],
  drawerOpen: true,
  sidebarOpen: false,
  page: "",
  modal: "",
  sportsmenu: true,
  chatOpen: false,
  isPlaying: false,
  isSports: false,
  isGrid: true,
  soundVolume: 1,
  showGameAnimation: true,
  maxBetAllow: true,
  gameHotKeyEnabled: false,
  showGameInfo: false,
  showHotkeyPanel: false,
};

const menu = createSlice({
  name: "menu",
  initialState,
  reducers: {
    activeItem(state, action) {
      state.selectedItem = action.payload;
    },

    openDrawer(state, action) {
      state.drawerOpen = action.payload;
    },

    ChangePage(state, action) {
      state.page = action.payload;
    },

    ShowModal(state, action) {
      state.modal = action.payload;
    },

    openSidebar(state, action) {
      state.sidebarOpen = action.payload;
    },

    changeSportsMenu(state, action) {
      state.sportsmenu = action.payload;
    },

    changeChatOpen(state, action) {
      state.chatOpen = action.payload;
    },

    activeGame(state, action) {
      state.isPlaying = action.payload;
    },

    activeSports(state, action) {
      state.isSports = action.payload;
    },

    deactiveGrid(state, action) {
      state.isGrid = action.payload;
    },

    setSoundVolume(state, action) {
      state.soundVolume = action.payload;
    },

    setGameAnimation(state, action) {
      state.showGameAnimation = action.payload;
    },

    setMaxBetAllow(state, action) {
      state.maxBetAllow = action.payload;
    },

    setGameHotKeyEnable(state, action) {
      state.gameHotKeyEnabled = action.payload;
    },

    visibleGameInfo(state, action) {
      state.showGameInfo = action.payload;
    },

    visibleHotKeyPanel(state, action) {
      state.showHotkeyPanel = action.payload;
    },
  },
});

export default menu.reducer;

export const {
  activeItem,
  openDrawer,
  ChangePage,
  openSidebar,
  changeSportsMenu,
  changeChatOpen,
  activeGame,
  activeSports,
  deactiveGrid,
  setSoundVolume,
  setGameAnimation,
  setMaxBetAllow,
  setGameHotKeyEnable,
  visibleGameInfo,
  visibleHotKeyPanel
} = menu.actions;
